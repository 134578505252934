import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import { EditOutlined, SwitcherOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import { log } from 'console';

const { Option } = Select;

export default function InstiuteList() {

    const fetchInstiuteList = useStoreActions((state) => state.customerSupport.fetchInstiuteList);
    const instiuteList = useStoreState((state) => state.customerSupport.instiuteList);
    const submitDeactiveInstitute = useStoreActions((state) => state.customerSupport.submitDeactiveInstitute);
    const submitActiveInstitute = useStoreActions((state) => state.customerSupport.submitActiveInstitute);
    const updateInstiutePackage = useStoreActions((state) => state.customerSupport.institutePackageUpdate);
    const updateInstiuteOfpsType = useStoreActions((state) => state.customerSupport.instituteOfpsTypeUpdate);
    const updateInstituteStatus = useStoreActions((state) => state.customerSupport.updateInstituteStatusFromCustomerSupport);

    const [instituteId, setInstituteId] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [ofpyTypeModalVisible, setOfpsTypeModalVisible] = useState(false);
    const [isInstituteStatusModalVisible, setInstituteStatusModalVisible] = useState(false);
    const [myform] = Form.useForm();
    const [statusUpdateform] = Form.useForm();
    
    useEffect(() => {
        fetchInstiuteList();
    }, []);

    const deActiveInstitute = (id) => {
        submitDeactiveInstitute(id);
    }

    const activeInstitute = (id) => {
        submitActiveInstitute(id);
    }

    const editPackageInfo = (value) => {
        let payload = {
            "instituteId": instituteId,
            "packageId": value.packageId,

        }
        updateInstiutePackage(payload);
        setIsModalVisible(false);

    };

    
    const updateOfpsType = (value) => {
        
        let payload = {
            "instituteId": instituteId,
            "ofpsType": value.ofpsType,

        }
        updateInstiuteOfpsType(payload);
        setOfpsTypeModalVisible(false);

    };

    const changeInstituteStatus = (value) => {

        let payload = {
            "instituteId": instituteId,
            "instituteStatus": value.instituteStatus,
        }

        updateInstituteStatus(payload);

        setInstituteStatusModalVisible(false);
    };


    const columns = [
        { title: 'Institute ID', dataIndex: 'instituteId', key: "instituteId", showOnResponse: true, showOnDesktop: true },
        { title: 'Institute Name', dataIndex: 'instituteName', key: "instituteName", showOnResponse: true, showOnDesktop: true },
        { title: 'Type', dataIndex: 'instituteType', key: "instituteType", showOnResponse: true, showOnDesktop: true },
        { title: 'Package', dataIndex: 'packageName', key: "packageName", showOnResponse: true, showOnDesktop: true },
        { title: 'Address', dataIndex: 'address', key: "address", showOnResponse: true, showOnDesktop: true },
        { title: 'Ofps Type', dataIndex: 'ofpsType', key: "ofpsType", showOnResponse: true, showOnDesktop: true },
        { title: 'Status', dataIndex: 'instituteStatus', key: "instituteStatus", showOnResponse: true, showOnDesktop: true },
        {
            title: 'Edit Status', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                    <Tooltip title="Edit Status">
                        <Button danger icon={<EditOutlined />} onClick={() => {
                            setInstituteStatusModalVisible(true);
                            setInstituteId(record?.instituteId);
                             statusUpdateform.setFieldsValue({
                                instituteStatus:record?.status
                            });
     
                        }}
                        />
                    </Tooltip>
                </Space>
                </>
        },

        {
            title: 'Package Edit', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                        <Tooltip title="Package Edit">
                            <Button danger icon={<EditOutlined />} onClick={() => {
                                setIsModalVisible(true);
                                myform.setFieldsValue({ packageId: record?.packageId })
                                setInstituteId(record?.instituteId);

                            }}
                            />
                        </Tooltip>
                    </Space>
                </>
        },

        {
            title: 'Ofps Type Edit', dataIndex: 'status', key: "status", showOnResponse: true, showOnDesktop: true, render: (text, record: any, index) =>
                <>
                    <Space size="middle">
                        <Tooltip title="Ofps Type Edit">
                            <Button danger icon={<EditOutlined />} onClick={() => {
                                setOfpsTypeModalVisible(true);
                                myform.setFieldsValue({ ofpsType: record?.ofpsType })
                                setInstituteId(record?.instituteId);

                            }}
                            />
                        </Tooltip>
                    </Space>
                </>
        }
    ];

    return (
        <>
            <Card title="Institute List">
                <Row className="m-t-mo-30">
                    <Col span={24}>
                        <div className="datatable-responsive-demo">
                            <TableView
                                antTableProps={{
                                    showHeader: true,
                                    columns,
                                    dataSource: instiuteList,
                                    filterData: instiuteList,
                                    pagination: true,
                                    bordered: true,
                                    rowKey: "instituteId",
                                }}
                                mobileBreakPoint={768}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>


            <Modal
                title="Edit Package Info"
                visible={isModalVisible}
                okButtonProps={{ form: 'edit', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="No"
                okText="Yes"
                centered   
            >


                <Form
                    layout="vertical"
                    onFinish={editPackageInfo}
                    id="edit"
                    form={myform}
                >

                    <Form.Item
                        name="packageId"
                        label="Package:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please select package" },
                        ]}
                    >
                        <Select placeholder="Select Package" allowClear style={{ width: "100%" }}>
                            <Option value={1}>Basic</Option>
                            <Option value={2}>Premium</Option>
                            <Option value={3}>SBL</Option>
                        </Select>
                    </Form.Item >

                </Form>

            </Modal>

            <Modal
                title="Edit Ofps Type Info"
                visible={ofpyTypeModalVisible}
                okButtonProps={{ form: 'editOfps', htmlType: 'submit' }}
                onCancel={() => setOfpsTypeModalVisible(false)}
                cancelText="No"
                okText="Yes"
                centered   
            >


                <Form
                    layout="vertical"
                    onFinish={updateOfpsType}
                    id="editOfps"
                    form={myform}
                >

                    <Form.Item
                        name="ofpsType"
                        label="Ofps Type:"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please Select Ofps Type" },
                        ]}
                    >
                        <Select placeholder="Select Ofps Type" allowClear style={{ width: "100%" }}>
                            <Option value='bkash'>bkash</Option>
                            <Option value='sonalibank'>sonalibank</Option>
                            <Option value='upaypgw'>upaypgw</Option>
                        </Select>
                    </Form.Item >

                </Form>

            </Modal>


            <Modal
                title="Institute Status"
                visible={isInstituteStatusModalVisible}
                okButtonProps={{ form: 'updateStatus', htmlType: 'submit' }}
                onCancel={() => setInstituteStatusModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
            >
                <Form
                    layout="vertical"
                    onFinish={changeInstituteStatus}
                    id="updateStatus"
                    form={statusUpdateform}
                >

                    <Form.Item
                        name="instituteStatus"
                        label="Institute Status"
                        className="title-Text"
                        rules={[
                            { required: true, message: "Please Select" },
                        ]}
                    >
                        <Select placeholder="Institute Status">
                            <Option key={1} value={1}>Active</Option>
                            <Option key={0} value={0}>Inactive</Option>
                            <Option key={2} value={2}>Pending</Option>
                        </Select>
                    </Form.Item>

                </Form>

            </Modal>

        </>
    )
}